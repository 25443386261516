<template>
  <div class="deploy-content">
    <div class="c-1 content-block" style="margin-right:1rem;" v-loadingx="actionKey === 'restart' && loading">
      <div class="content-top">
        <fm-title title-text="启动记录" :title-menus="titleMenus" @clickTitleMenu="clickTitleMenu"></fm-title>
      </div>
      <div class="content-body" style="display: block;">
        <fm-table-new
          auto-height
          full
          border="row"
          :stripe="false"
          :columns="columnList"
          :data-list="dataList">
        </fm-table-new>
      </div>
      <div class="loading-div" v-if="actionKey === 'ur' && loading">
        <span>进度: {{percentage}}%</span>
      </div>
    </div>
    <div class="c-1 content-block" style="margin:1rem 1rem 1rem 0;" v-loadingx="actionKey === 'restart_f' && loading">
      <div class="content-top">
        <fm-title title-text="上传记录" :title-menus="titleMenus2" @clickTitleMenu="clickTitleMenu"></fm-title>
      </div>
      <div class="content-body" style="display: block;">
        <fm-table-new
          auto-height
          full
          border="row"
          :stripe="false"
          :columns="columnList2"
          :data-list="dataList2">
          <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
        </fm-table-new>
      </div>
      <div class="loading-div" v-if="actionKey === 'upload' && loading">
        <span>进度: {{percentage}}%</span>
      </div>
    </div>
    <fm-form-dialog
      form-title="选择系统文件"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      form-width="800px"
      label-alone
      label-align="left"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
    <input-file-update
      :open-dialog.sync="openDialogFile"
      @chooseFile="chooseFile"
      @handleClose="openDialogFile = false">
    </input-file-update>
  </div>
</template>

<script>
import {
  deployRequest,
  appServiceConfigRequest,
  fileRequest
} from '@/api'

import fileConf from '@/syslib/file_conf'

import {
  getFullNameByFullPath
} from '@/syslib/file_conf'
import TableActions from '../../../components/base/TableActions.vue'

export default {
  components: { TableActions },
  name: 'deploy_cmp',
  props: {
    data: {
      type: Number
    },
    appService: {
      type: Object
    },
    appVersion: {
      type: Object
    },
    appServiceConfig: {
      type: Object
    }
  },
  created () {
    this.appServiceConfigId = this.data
    this.detail()
  },
  methods: {
    async loadConfigFiles () {
      let files1 = await fileRequest.get({
        path: fileConf.appVersion ? fileConf.appVersion.getPath(this.appVersion) : null
      })
      let files2 = await fileRequest.get({
        path: fileConf.appService ? fileConf.appService.getPath(this.appService) : null
      })
      files1 = files1.concat(files2)
      this.chooseFiles = files1.filter(v => !v.isDirectory).map((v) => {
        return {
          key: v.path,
          label: v.name + ':' + getFullNameByFullPath(v),
          data: v
        }
      })
    },
    formSubmit (data) {
      this.openDialog = false
      this.chooseFile(data.path)
    },
    async clickTitleMenu (key) {
      this.actionKey = key
      if (key === 'restart') {
        this.chooseData2 = null
        this.restart()
      } else if (key === 'check') {
        this.checkStatus()
      } else if (key === 'stop') {
        this.stop()
      } else {
        const result = await this.$dialog.confirm({title: '系统提示', content: '是否使用部署配置中的文件?'})
        if (result) {
          await this.loadConfigFiles()
          this.openDialog = true
        } else {
          this.openDialogFile = true
        }
      }
    },
    chooseFile (file) {
      this.loading = true
      this.percentage = 0
      if (this.actionKey === 'upload') {
        deployRequest.upload(this.appServiceConfigId, file).then(() => {
          this.loadUploadlog()
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
        this.openDialogFile = false
      } else if (this.actionKey === 'ur') {
        deployRequest.ur(this.appServiceConfigId, file).then(() => {
          this.loadUploadlog()
          this.loadStartlog()
          this.loading = false
        }).catch(() => {
          this.loadUploadlog()
          this.loadStartlog()
          this.loading = false
        })
        this.openDialogFile = false
      }
      this.loadProgress()
    },
    loadProgress () {
      setTimeout(() => {
        deployRequest.progress({id: this.appServiceConfigId}).then((data) => {
          if (typeof data.result !== 'undefined') {
            this.percentage = (data.result ? data.result * 100 : 100).toFixed(0)
          }
          if (this.loading && typeof data.result === 'undefined' || (data.result && data.result !== 1)) {
            this.loadProgress()
          }
        })
      }, 200)
    },
    detail () {
      this.loadUploadlog()
      this.loadStartlog()
    },
    loadStartlog () {
      appServiceConfigRequest.getRestartLog({appServiceConfigId: this.appServiceConfigId}).then((data) => {
        this.dataList = data
      })
    },
    checkStatus () {
      deployRequest.check({
        id: this.appServiceConfigId
      }).then((data) => {
        this.$notice.info({
          title: '检查结果',
          desc: data
        })
      })
    },
    async stop () {
      let result = await this.$dialog.confirm({title: '系统提示', content: '确定停止该服务吗?'})
      if (result) {
        let data = await deployRequest.stop({
          id: this.appServiceConfigId
        })
        if (data && data.error) {
          this.$notice.warning({
            title: '系统提示',
            desc: '服务停止失败:' + data.result
          })
        } else if (data && data.result) {
          this.$notice.info({
            title: '系统提示',
            desc: '停止信息:' + data.result
          })
        } else {
          this.$notice.warning({
            title: '系统提示',
            desc: '返回结果为空'
          })
        }
      }
    },
    loadUploadlog () {
      appServiceConfigRequest.getUploadLog({appServiceConfigId: this.appServiceConfigId}).then((data) => {
        this.dataList2 = data
      })
    },
    tableAction (data) {
      this.actionKey = 'restart_f'
      this.chooseData2 = data.data
      this.restart()
    },
    restart () {
      let parm = {
        id: this.appServiceConfigId
      }
      if (this.chooseData2) {
        parm.appServiceUploadId = this.chooseData2.id
      }
      this.loading = true
      deployRequest.restart(parm).then(() => {
        this.loadStartlog()
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  },
  computed: {
    formParms () {
      return [{
        type: 'select',
        label: '系统文件',
        key: 'path',
        selectDatas: this.chooseFiles,
        check: {
          required: true
        }
      }]
    }
  },
  data () {
    return {
      chooseFiles: [],
      percentage: 0,
      loading: false,
      actionKey: '',
      openDialogFile: false,
      openDialog: false,
      columnList: [
        {
          field: 'createTime',
          title: '时间',
          width: 100,
          fixed: 'left'
        },
        {
          field: 'uploadPkgName',
          title: '使用文件',
          fixed: 'left'
        },
        {
          field: 'restartConfig',
          title: '重启配置',
          width: 200
        },
        {
          field: 'userName',
          title: '处理人',
          filter: true,
          width: 100
        },
        {
          field: 'result',
          title: '结果',
          width: 100
        },
        {
          field: 'isSuccess',
          title: '是否成功',
          fixed: 'right',
          filter: true,
          search: false,
          filterMultiple: false,
          filters: [
            { label: '<span class="g-badge-success"></span>是', key: true },
            { label: '<span class="g-badge-danger"></span>否', key: false }
          ],
          render: (h, rowData) => {
            return h('div', [
              h('span', {
                'class': {
                  'g-badge-success': rowData.isSuccess,
                  'g-badge-danger': !rowData.isSuccess
                }
              }),
              rowData.isSuccess ? '是' : '否'
            ])
          }
        }
      ],
      columnList2: [
        {
          field: 'createTime',
          title: '时间'
        },
        {
          title: '文件目录',
          field: 'uploadPkgPath'
        },
        {
          title: '文件名',
          field: 'uploadPkgName'
        },
        {
          title: '处理人',
          field: 'userName',
          filter: true
        },
        {
          title: '操作', slot: 'actions', width: 80, fixed: 'right', search: false
        }
      ],
      dataList: [],
      dataList2: [],
      titleMenus: [{key: 'check', label: '检查'}, {key: 'restart', label: '重启'}, {key: 'ur', label: '部署'}, {key: 'stop', label: '停止'}],
      titleMenus2: [{key: 'upload', label: '上传'}],
      tableActions: [{key: 'restart', label: '重启'}],
      chooseData2: null,
      appServiceConfigId: null
    }
  }
}
</script>

<style scoped lang="less">
.loading-div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255,255,255,.9);
  width: 100%;
}
.c-1 {
  flex: 1;
  margin: 1rem 0 0 0;
  background: #fff;
  border-radius: 0.3rem;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1);
  position: relative;
}
.content-body {
  flex: 1;
  display: flex;
}
.deploy-content {
  display: flex;
  height: 70vh;
}
.info-block {
  width: 100%;
}
.info-item {
  min-height: 3rem;
  display: flex;
  flex-direction: column;
  margin-left: 0rem;
}
.hover-show-btn {
  display: none;
}
.info-item-file:hover {
  .hover-show-btn {
    display: flex !important;
  }
}
.info-label:hover {
  .hover-show-btn {
    display: flex !important;
  }
}
.info-item-text {
  min-height: 3rem;
  display: flex;
  flex-wrap: wrap;
  .info-item {
    background: rgba(255,255,255,0.05);
    min-height: 2rem;
    margin: 0.5rem 0 0.5rem 0;
    border-radius: 0.5rem;
    .info-label {
      margin-right: 0.2rem;
      font-size: 1rem;
    }
  }
}
.info-label {
  color: #777;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  margin-right: 2rem;
}
.info-value {
  color: #333;
  flex-direction: column;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
.info-item-f {
  flex-direction: row !important;
}
</style>
