<template>
  <div class="deploy-page" v-if="appServiceData">
    <div class="left-c">
      <div class="c-c">
        <type-item
          :dataParm="{id: appServiceData.id}"
          :config="appServiceListConfig">
        </type-item>
        <type-item
          :dataParm="{id: appServiceConfigId}"
          :config="appServiceConfigList"
          key="choosed"
          v-if="!chooseIng">
        </type-item>
        <type-item
          :dataParm="{appServiceId: appServiceData.id}"
          :config="appServiceConfigListChoose"
          key="chooseing"
          @checked="checked"
          v-if="chooseIng">
        </type-item>
        <type-item
          :dataParm="{serverId: appServiceData.serverId}"
          :config="serverConfigList">
        </type-item>
      </div>
    </div>
    <div class="right-c">
      <deploy-cmp class="right-c" :app-service-config="appServiceConfigData" :app-version="appVersion" :app-service="appServiceData" v-if="!chooseIng" :data="appServiceConfigId"></deploy-cmp>
    </div>
  </div>
</template>

<script>
import {
  localstorageHelper
} from '@/fmlib'

import TypeItem from '@/components/datainfo/TypeItem'
import DeployCmp from './deploy/deploy_cmp'

import {
  appServiceRequest
} from '@/api'

import {
  appServiceList
} from '@/data_conf/app_service'

import {
  appServiceConfigList
} from '@/data_conf/app_service_config'

import {
  serverConfigList
} from '@/data_conf/server_config'

export default {
  components: {
    TypeItem,
    DeployCmp
  },
  data () {
    return {
      chooseIng: true,
      appServiceConfigId: null,
      appServiceConfigData: null,
      appServiceId: null,
      appServiceData: null,
      titleMenus: [{key: 'restart', label: '重启'}, {key: 'ur', label: '部署'}],
      titleMenus2: [{key: 'upload', label: '上传'}],
      tableActions: [{key: 'restart', label: '重启'}],
      appServiceConfigList: appServiceConfigList({titleMenus: [{
        key: 'choose',
        label: '重新选择',
        dealFun: () => {
          this.chooseIng = true
        }
      }], childParm: {defaultOpen: true}}),
      appServiceConfigListChoose: appServiceConfigList({childParm: {defaultOpen: false, showCheckbox: true}}),
      serverConfigList: serverConfigList({}, {}),
      appServiceListConfig: appServiceList(),
      columnList: [{
        field: 'createTime',
        title: '时间'
      },
      {
        field: 'uploadPkgName',
        title: '使用文件'
      },
      {
        field: 'restartConfig',
        title: '重启配置'
      },
      {
        field: 'userName',
        title: '处理人'
      },
      {
        field: 'result',
        title: '结果'
      },
      {
        field: 'isSuccess',
        title: '是否成功',
        render: (h, rowData) => {
          return h('div', rowData.isSuccess ? '是' : '否')
        }
      }],
      columnList2: [{
        field: 'createTime',
        title: '时间'
      },
      {
        title: '文件目录',
        field: 'uploadPkgPath'
      },
      {
        title: '文件名',
        field: 'uploadPkgName'
      },
      {
        title: '处理人',
        field: 'userName'
      }],
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.query && to.query.appServiceId) {
      next()
    } else {
      next({name: 'work.app_service'})
    }
  },
  computed: {
    appVersion () {
      return {
        id: this.appServiceData.appVersionId,
        appId: this.appServiceData.appId
      }
    }
  },
  created () {
    this.$store.dispatch('loadRestartTypeList')
    this.appServiceId = this.$router.history.current.query.appServiceId
    this.loadData()
  },
  activated () {
    this.$store.dispatch('setIsPop', false)
  },
  deactivated () {
    this.$store.dispatch('setIsPop', localstorageHelper.getData('is_pop') === '1')
  },
  methods: {
    checked (data) {
      if (data.checked) {
        this.appServiceConfigId = data.data.id
        this.appServiceConfigData = data.data
      }
      this.chooseIng = false
    },
    loadData () {
      appServiceRequest.get({id: this.appServiceId}).then((data) => {
        if (data.length === 0) {
          this.$notice.error({
            title: '系统提示',
            desc: '未找到服务，请重新选择!'
          })
          this.$router.push({name: 'work.app_service'})
        } else {
          this.appServiceData = data[0]
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.deploy-page {
  display: flex;
  height: calc(100% - 4rem);
}
.sys-info {
  display: flex;
}
.menu-tree {
  width: 10%;
  overflow: auto;
  height: 100%;
}
.sys-info-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  max-height: 100%;
}
.left-c {
  height: 100%;
  max-height: 100%;
  flex: 3;
  overflow-y: auto;
}
.right-c {
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 4;
}
.c-c {
  margin: 1rem;
  border-radius: 0.3rem;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1);
  background: #fff;
}
.info-node-dev {
  margin: 0.25rem 1rem 1rem 0;
  background: rgba(0,0,0,0.03);
  border-radius: 0.3rem;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1);
}
</style>